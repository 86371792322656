import { useMemo } from 'react';

const useUTM = (baseRoute: string, campaignId: any = '001') => {
  const campaignQueryStrings = {
    '001':
      'utm_source=Suburb+Scoring+Tool&utm_medium=Property+Pathfinder&utm_campaign=ppf+free+pgp',
    '002':
      'utm_source=property+portfolio+roadmap&utm_medium=Property+Pathfinder&utm_campaign=pgp+ai+tool',
  };

  const combinedRoute = useMemo(
    () => `${baseRoute}?${campaignQueryStrings[campaignId]}`,
    [baseRoute, campaignQueryStrings],
  );

  return combinedRoute;
};

export default useUTM;
