import { CloseOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { WistiaPlayer } from '@wistia/wistia-player-react';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import PauseButton from '../../../foundation/assets/svgs/PauseButton';
import PlayButton from '../../../foundation/assets/svgs/PlayButton';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';

const withPointer = () => {
  const WithPointerComponent = (props: any) => {
    const { isDesktopViewport } = useViewport();

    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition]: any = useState(null);

    const pointerRef: any = useRef(null);
    const containerRef: any = useRef(null);

    const [playing, setPlaying]: any = useState(true);
    const [ended, setEnded]: any = useState(false);
    const [dragged, setDragged] = useState(false);

    const playerRef: any = useRef(null);
    const previousAngle = useRef(0);

    const calculateAngle = (pointerRect, targetRect) => {
      const pointerCenter = {
        x: (pointerRect.left + pointerRect.right) / 2,
        y: (pointerRect.top + pointerRect.bottom) / 2,
      };

      const targetCenter = {
        x: (targetRect.left + targetRect.right) / 2,
        y: (targetRect.top + targetRect.bottom) / 2,
      };

      const dx = targetCenter.x - pointerCenter.x;
      const dy = targetCenter.y - pointerCenter.y;

      let angle = Math.atan2(dy, dx) * (180 / Math.PI);

      /*
       * The +90 is for correcting the pointer's orientation in this specific case
       */
      angle = (Math.round(angle) + 360 + 90) % 360;

      const angleDiff = angle - previousAngle.current;
      if (angleDiff > 180) {
        angle -= 360;
      } else if (angleDiff < -180) {
        angle += 360;
      }

      previousAngle.current = angle;

      return angle;
    };

    const togglePlayPause = (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (playerRef?.current) {
        if (playing) {
          playerRef.current.pause();
        } else {
          setEnded(false);
          playerRef.current.play();
        }

        setPlaying(!playing);
      }
    };

    const handleMove = (e) => {
      if (isDragging) {
        const clientX =
          e.clientX || (e.touches && e.touches[0] && e.touches[0].clientX);
        const clientY =
          e.clientY || (e.touches && e.touches[0] && e.touches[0].clientY);

        if (clientX !== undefined && clientY !== undefined) {
          setPosition({
            top:
              clientY -
              (pointerRef.current ? pointerRef.current.clientHeight / 2 : 0),
            left:
              clientX -
              (pointerRef.current ? pointerRef.current.clientWidth / 2 : 0),
          });
        }

        setDragged(true);
      }
    };

    const handleStart = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    useEffect(() => {
      const element = pointerRef.current;

      const handleEventListeners = (action) => {
        const method =
          action === 'add' ? 'addEventListener' : 'removeEventListener';

        document[method]('mousemove', handleMove);
        document[method]('mouseup', handleEnd);
        element[method]('mousedown', handleStart);

        if (element) {
          element[method]('touchmove', handleMove);
          element[method]('touchend', handleEnd);
          element[method]('touchstart', handleStart);
        }
      };

      handleEventListeners('add');

      return () => handleEventListeners('remove');
    }, [isDragging]);

    useEffect(() => {
      if (props?.targetRef?.current && containerRef?.current && !dragged) {
        const targetRect = props.targetRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let left = targetRect.right;
        let top = targetRect.top;

        if (left + containerRect.width > viewportWidth) {
          if (targetRect.left - containerRect.width >= 0) {
            left = targetRect.left - containerRect.width;
          } else {
            left = viewportWidth - containerRect.width;
          }
        }

        if (top + containerRect.height > viewportHeight) {
          if (targetRect.bottom + containerRect.height <= viewportHeight) {
            top = targetRect.bottom;
          } else {
            top = viewportHeight - containerRect.height;
          }
        } else if (top < 0) {
          top = targetRect.bottom;
        }

        setPosition({ top, left });
      }
    }, [props.targetRef, containerRef, props.videoMediaId]);

    useEffect(() => {
      if (playerRef?.current) {
        setEnded(false);
        setPlaying(true);
      }
    }, [props.videoMediaId, playerRef]);

    useEffect(() => {
      let angle: number;

      const applyRotation = () => {
        pointerRef.current.style.transform = `rotate(${angle}deg)`;
      };

      const updatePointerRotation = () => {
        if (pointerRef.current && props?.targetRef.current) {
          const pointerRect = pointerRef.current.getBoundingClientRect();
          const targetRect = props.targetRef.current.getBoundingClientRect();
          angle = calculateAngle(pointerRect, targetRect);

          requestAnimationFrame(applyRotation);
        }
      };

      const debouncedUpdatePointerRotation = debounce(
        updatePointerRotation,
        isDesktopViewport ? 0 : 100,
      );

      updatePointerRotation();

      const handleScroll = () => {
        debouncedUpdatePointerRotation();
      };

      const contentElement = document.querySelector('.c-app-layout__content');
      if (contentElement) {
        contentElement.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (contentElement) {
          contentElement.removeEventListener('scroll', handleScroll);
        }
        debouncedUpdatePointerRotation.cancel();
      };
    }, [props?.targetRef?.current, position]);

    if (!props?.targetRef) {
      return null;
    }

    return (
      <div
        ref={containerRef}
        className="l-video-explainer"
        style={{
          top: position?.top || 0,
          left: position?.left || 0,
          cursor: isDragging ? 'grabbing' : 'grab',
          opacity: position ? 1 : 0,
        }}
      >
        <div className="l-video-explainer__player">
          <WistiaPlayer
            ref={playerRef}
            key={props.videoMediaId}
            mediaId={props.videoMediaId}
            playerColor="#00766c"
            style={{
              display: 'block',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '270px',
              height: '400px',
              opacity: ended ? 0.4 : 1,
            }}
            autoplay
            playPauseNotifier={false}
            onEnded={() => {
              setEnded(true);
              setPlaying(false);
            }}
          />
        </div>
        <button
          ref={pointerRef}
          className="l-video-explainer__pointer"
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          onMouseDown={handleStart}
          onMouseUp={handleEnd}
          onTouchStart={handleStart}
          onTouchEnd={handleEnd}
        >
          <div className="l-video-explainer__pointer-arrow" />
          <div className="l-video-explainer__pointer-body" />
        </button>
        <div className="l-video-explainer__spinner">
          {!ended && (
            <Spin
              indicator={
                <Loading3QuartersOutlined style={{ fontSize: '34px' }} spin />
              }
            />
          )}
        </div>
        {!isDragging && (
          <button
            className={`l-video-explainer__play-button${playing ? ' l-video-explainer__play-button--hidden' : ''}`}
            onClick={togglePlayPause}
          >
            {playing ? (
              <PauseButton
                color="#232e40"
                className="l-video-explainer__pause-button-icon"
              />
            ) : (
              <PlayButton
                className="l-video-explainer__play-button-icon"
                color="#232e40"
              />
            )}
          </button>
        )}
        {!isDragging && (
          <button
            className="l-video-explainer__close-button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setEnded(false);
              setPlaying(true);
              props.closeHandler();
            }}
          >
            <CloseOutlined className="l-video-explainer__close-button-icon" />
          </button>
        )}
      </div>
    );
  };

  return WithPointerComponent;
};

export default withPointer;
