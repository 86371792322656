import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import { percentageParser } from '../../../foundation/utils/helperFunctions';
import {
  selectClientRiskProfileListOptions,
  selectClientRiskProfileValues,
  selectFinancialGoalListOptions,
  selectLmiPaymentModeListOptions,
  selectOptimiseForListOptions,
} from '../../property/redux/selectors';

export const useInputFields = (values: any, setFieldValue: any) => {
  const financialGoalList = useSelector(selectFinancialGoalListOptions);
  const optimiseForList = useSelector(selectOptimiseForListOptions);
  const lmiPaymentModeList = useSelector(selectLmiPaymentModeListOptions);
  const clientRiskProfileList = useSelector(selectClientRiskProfileListOptions);

  const clientRiskProfileValues = useSelector(selectClientRiskProfileValues);

  const targetYearsOptions = useMemo(() => {
    const options: any[] = [];

    for (let i = 1; i < 31; i++) {
      options.push({
        key: i,
        value: i,
      });
    }

    return options;
  }, []);

  const handleRiskProfileChange = (v: any) => {
    if (v && clientRiskProfileValues) {
      let clientRiskProfile;

      for (const riskProfile of clientRiskProfileValues) {
        if (riskProfile.riskType === v) {
          clientRiskProfile = {
            ...riskProfile,
            clientRiskProfile: riskProfile.riskType,
            maxNegativeCashFlowYearlySavings: percentageParser(
              riskProfile.maxNegativeCashFlowYearlySavings,
            ),
            clientRefinancingMaxLvr: percentageParser(
              riskProfile.clientRefinancingMaxLvr,
            ),
          };
          break;
        }
      }

      if (clientRiskProfile) {
        for (const field of riskProfileFieldArray) {
          setFieldValue(field.name, clientRiskProfile[field.name]);
        }
      }
    }
  };

  const goalsFieldArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Financial Goal',
        name: 'financialGoal',
        key: 'financialGoal',
        type: 'select',
        placeholder: undefined,
        isRequired: true,
        requiredMessage: 'Financial Goal is required.',
        hasWrapperCol: true,
        options: financialGoalList,
        colSpan: 11,
        colOffset: 0,
      },
      {
        label: 'Target Years',
        name: 'targetYears',
        key: 'targetYears',
        type: 'select',
        options: targetYearsOptions,
        isRequired: true,
        requiredMessage: 'Target Years is required',
        min: 1,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
      },
      {
        label: 'Financial Target',
        name: 'financialTarget',
        key: 'financialTarget',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Financial Target is required',
        min: 1,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        addonBefore: '$',
      },
      {
        label: 'Target Year',
        name: 'targetYear',
        key: 'targetYear',
        type: 'text',
        isRequired: true,
        requiredMessage: 'Target Year is required',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        disabled: true,
      },
      {
        label: 'Optimise For',
        name: 'optimiseFor',
        key: 'optimiseFor',
        type: 'select',
        placeholder: undefined,
        isRequired: true,
        requiredMessage: 'Optimise For is required.',
        hasWrapperCol: true,
        options: optimiseForList,
        colSpan: 11,
        colOffset: 0,
      },
    ],
    [financialGoalList, targetYearsOptions, optimiseForList],
  );

  const financialsFieldArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Cash Available',
        name: 'cashAvailable',
        key: 'cashAvailable',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Cash Available is required',
        min: 1,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        addonBefore: '$',
      },
      {
        label: 'Yearly Savings',
        name: 'yearlySavings',
        key: 'yearlySavings',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Yearly Savings is required',
        min: 1,
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        addonBefore: '$',
      },
      {
        label: 'Borrowing Capacity',
        name: 'borrowingCapacity',
        key: 'borrowingCapacity',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Borrowing Capacity is required',
        min: 1,
        colSpan: 11,
        colOffset: 0,
        addonBefore: '$',
        hasWrapperCol: true,
      },
      {
        label: 'Loan Term (Years)',
        name: 'loanTerm',
        key: 'loanTerm',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Loan Term (Years) is required',
        min: 1,
        defaultValue: 30,
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        disabled: true,
      },
      {
        label: 'I/O Term (Years)',
        name: 'loanIoTerm',
        key: 'loanIoTerm',
        type: 'number',
        isRequired: true,
        requiredMessage: 'I/O Term (Years) is required',
        min: 1,
        defaultValue: 5,
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        disabled: true,
      },
      {
        label: 'P&I Term (Years)',
        name: 'loanPiTerm',
        key: 'loanPiTerm',
        type: 'number',
        isRequired: true,
        requiredMessage: 'P&I Term (Years) is required',
        min: 1,
        defaultValue: 5,
        colSpan: 11,
        disabled: true,
        colOffset: 0,
        hasWrapperCol: true,
      },
      {
        label: 'Max. Client Purchase LVR',
        name: 'maxClientPurchaseLvr',
        key: 'maxClientPurchaseLvr',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Max. Client Purchase LVR is required',
        min: 1,
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        addonBefore: '%',
        max: 100,
        step: 1,
        defaultValue: 88,
        disabled: true,
      },
      {
        label: 'LMI Payment Mode',
        name: 'lmiPaymentMode',
        key: 'lmiPaymentMode',
        type: 'select',
        isRequired: true,
        requiredMessage: 'LMI Payment Mode is required',
        colSpan: 11,
        colOffset: 0,
        defaultValue: 'Add to Loan Amount',
        hasWrapperCol: true,
        options: lmiPaymentModeList,
        disabled: true,
      },
      {
        label: 'Number of Properties Signed Up For',
        name: 'numberOfPropertiesSignedUp',
        key: 'numberOfPropertiesSignedUp',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Number of Properties Signed Up For is required',
        colSpan: 11,
        colOffset: 0,
        min: 1,
        hasWrapperCol: true,
        defaultValue: 1,
        disabled: true,
      },
    ],
    [lmiPaymentModeList],
  );

  const timingFieldArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Project Start Date',
        name: 'projectStartDate',
        key: 'projectStartDate',
        type: 'month',
        isRequired: true,
        requiredMessage: 'Project Start Date is required',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        format: 'MM/YYYY',
        disabled: true,
      },
      {
        label: 'First Purchase Date',
        name: 'firstPurchaseDate',
        key: 'firstPurchaseDate',
        type: 'month',
        isRequired: true,
        requiredMessage: 'First Purchase Date is required',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        format: 'MM/YYYY',
        disabled: true,
      },
      {
        label: 'End of Purchase Period',
        name: 'endOfPurchasePeriod',
        key: 'endOfPurchasePeriod',
        type: 'month',
        isRequired: true,
        requiredMessage: 'End of Purchase Period is required',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 0,
        format: 'MM/YYYY',
        disabled: true,
      },
    ],
    [],
  );

  const riskProfileFieldArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Client Risk Profile',
        name: 'clientRiskProfile',
        key: 'clientRiskProfile',
        type: 'select',
        isRequired: true,
        requiredMessage: 'Client Risk Profile is required',
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        options: clientRiskProfileList,
        defaultValue: 'Moderate',
        onChange: handleRiskProfileChange,
      },
      {
        label: 'Cash Buffer',
        name: 'cashBuffer',
        key: 'cashBuffer',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Cash Buffer is required',
        colSpan: 11,
        colOffset: 0,
        min: 0,
        hasWrapperCol: true,
        defaultValue: 0,
        addonBefore: '$',
        disabled: true,
      },
      {
        label: 'Cash Buffer Lower Limit',
        name: 'cashBufferLowerLimit',
        key: 'cashBufferLowerLimit',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Cash Buffer Lower Limit is required',
        colSpan: 11,
        colOffset: 0,
        min: 0,
        hasWrapperCol: true,
        defaultValue: 0,
        addonBefore: '$',
        disabled: true,
      },
      {
        label: 'Use of Portfolio Cashflow',
        name: 'useOfPortfolioCashFlow',
        key: 'useOfPortfolioCashFlow',
        type: 'select',
        isRequired: true,
        requiredMessage: 'Use of Portfolio Cashflow is required',
        colSpan: 11,
        colOffset: 0,
        hasWrapperCol: true,
        options: [
          { key: 'Yes', value: true },
          { key: 'No', value: false },
        ],
        defaultValue: 'Moderate',
        disabled: true,
      },
      {
        label: 'Max. Negative Cashflow % of Yearly Savings',
        name: 'maxNegativeCashFlowYearlySavings',
        key: 'maxNegativeCashFlowYearlySavings',
        type: 'number',
        isRequired: true,
        requiredMessage:
          'Max. Negative Cashflow % of Yearly Savings is required',
        colSpan: 11,
        colOffset: 0,
        min: 0,
        hasWrapperCol: true,
        defaultValue: 0,
        addonBefore: '%',
        disabled: true,
      },
      {
        label: 'Client Refinancing Max. LVR',
        name: 'clientRefinancingMaxLvr',
        key: 'clientRefinancingMaxLvr',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Client Refinancing Max. LVR is required',
        colSpan: 11,
        colOffset: 0,
        min: 0,
        hasWrapperCol: true,
        defaultValue: 0,
        addonBefore: '%',
        disabled: true,
      },
      {
        label: 'Max. Number of Properties Sold',
        name: 'maxNumberOfPropertiesSold',
        key: 'maxNumberOfPropertiesSold',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Max. Number of Properties Sold is required',
        colSpan: 11,
        colOffset: 0,
        min: 0,
        hasWrapperCol: true,
        defaultValue: 0,
        disabled: true,
      },
      {
        label: 'Min. Years for Holding a Property',
        name: 'minYearsForHoldingProperty',
        key: 'minYearsForHoldingProperty',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Min. Years for Holding a Property is required',
        colSpan: 11,
        colOffset: 0,
        min: 0,
        hasWrapperCol: true,
        defaultValue: 0,
        disabled: true,
      },
    ],
    [clientRiskProfileList],
  );

  return [
    goalsFieldArray,
    financialsFieldArray,
    timingFieldArray,
    riskProfileFieldArray,
  ];
};
