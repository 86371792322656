import { Col, DatePicker, Input, InputNumber } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import * as Yup from 'yup';

import Select from '../../foundation/components/select/Select';
import { FieldType } from '../components/form_modal/form_modal_types';
import YearField from '../components/year_field/YearField';
import { useRole } from '../cutom_hooks/useRole';
import { useViewport } from '../cutom_hooks/useViewport';
import { numberFormatter } from './helperFunctions';

const { TextArea } = Input;

export const YearFieldRenderer = ({
  formField,
  errors,
  values,
  setFieldValue,
  onChange,
  isClientView,
  isAgencyLeadView,
}: {
  formField: FieldType;
  values: any;
  errors: any;
  setFieldValue?: any;
  onChange?: (v: string) => void;
  isClientView: boolean;
  isAgencyLeadView: boolean;
}) => (
  <div className="c-form-field" key={formField.key}>
    <div className="c-form-field__label">{formField.label}:</div>
    <div className="c-form-field__wrapper">
      <YearField
        // name={formField.name}
        onChange={(v: string) => {
          setFieldValue(formField.name, v ? dayjs(v).format('YYYY') : null);
          if (onChange) {
            onChange(v);
          }
        }}
        value={
          values[formField.name] ? dayjs(values[formField.name]) : undefined
        }
        className="c-date-field"
        disabled={isClientView || isAgencyLeadView}
        disabledDate={(date: Dayjs) => {
          if (formField.disabledDate) {
            return dayjs(date).format('YYYY') < formField.disabledDate;
          } else {
            return false;
          }
        }}
        picker="year"
      />
      <div className="c-form-field__error">
        {errors[formField.name] ? errors[formField.name] : undefined}
      </div>
    </div>
  </div>
);

export const MonthFieldRenderer = ({
  formField,
  errors,
  values,
  setFieldValue,
  onChange,
  isClientView,
  isAgencyLeadView,
}: {
  formField: FieldType;
  values: any;
  errors: any;
  setFieldValue?: any;
  isClientView: boolean;
  isAgencyLeadView: boolean;
  onChange?: (v: string) => void;
}) => {
  return (
    <div className="c-form-field" key={formField.key}>
      <div className="c-form-field__label">{formField.label}:</div>
      <div className="c-form-field__wrapper">
        <YearField
          // name={formField.name}
          onChange={(v: string) => {
            setFieldValue(
              formField.name,
              v
                ? dayjs(v).format(
                    formField.format ? formField.format : 'MMMM YYYY',
                  )
                : null,
            );
            if (onChange) {
              onChange(v);
            }
          }}
          value={
            values[formField.name]
              ? dayjs(values[formField.name], formField.format)
              : undefined
          }
          className="c-date-field"
          disabled={isClientView || isAgencyLeadView || formField.disabled}
          disabledDate={(date: Dayjs) => {
            if (formField.disabledDate) {
              return (
                dayjs(date) < dayjs(formField.disabledDate, formField.format)
              );
            } else {
              return false;
            }
          }}
          picker="month"
        />
        <div className="c-form-field__error">
          {errors[formField.name] ? errors[formField.name] : undefined}
        </div>
      </div>
    </div>
  );
};

export default (
  handleChange: any,
  values: any,
  errors: any,
  handleBlur: any,
  formFieldsArray: FieldType[],
  setFieldValue?: any,
) => {
  const { isDesktopViewport } = useViewport();
  const [isClientView, , , , , isAgencyLeadView] = useRole();

  const fields: any[] = [];

  const renderTextAreaField = (formField: FieldType) => (
    <div className="c-form-field" key={formField.name}>
      <div className="c-form-field__label">{formField.label}:</div>
      <div className="c-form-field__wrapper">
        <TextArea
          name={formField.name}
          onChange={handleChange}
          value={values && values[formField.name]}
          onBlur={handleBlur}
          placeholder={formField.placeholder}
          disabled={formField.disabled || isClientView || isAgencyLeadView}
          rows={formField.rows}
          maxLength={formField.maxLength}
        />
        <div className="c-form-field__error">
          {errors[formField.name] ? errors[formField.name] : undefined}
        </div>
      </div>
    </div>
  );

  const renderInputField = (formField: FieldType) => (
    <div className="c-form-field" key={formField.name}>
      <div className="c-form-field__label">{formField.label}:</div>
      <div className="c-form-field__wrapper">
        <Input
          name={formField.name}
          onChange={handleChange}
          type={formField.type}
          value={values && values[formField.name]}
          onBlur={handleBlur}
          placeholder={formField.placeholder}
          disabled={formField.disabled || isClientView || isAgencyLeadView}
          addonBefore={formField.addonBefore}
          // min={formField.min}
          // max={formField.max}
          step={formField.step}
        />
        <div className="c-form-field__error">
          {errors[formField.name] ? errors[formField.name] : undefined}
        </div>
      </div>
    </div>
  );

  const renderInputNumberField = (
    formField: FieldType,
    isSingleDigit: boolean,
  ) => {
    const props = !isSingleDigit
      ? {
          // @ts-ignore
          parser: (value: string) => value.replace(/\$\s?|(,*)/g, ''),
          // @ts-ignore
          formatter: (value) => {
            // @ts-ignore
            return value !== null && value !== undefined && value !== ''
              ? numberFormatter(value, '', true)
              : value;
          },
        }
      : {};

    return (
      <div className="c-form-field">
        <div className="c-form-field__label">{formField.label}:</div>
        <div className="c-form-field__wrapper">
          {/* @ts-ignore */}
          <InputNumber
            name={formField.name}
            onChange={
              formField.onChange
                ? formField.onChange
                : (v: number) => setFieldValue(formField.name, v)
            }
            value={values && values[formField.name]}
            onBlur={handleBlur}
            placeholder={formField.placeholder}
            disabled={formField.disabled || isClientView || isAgencyLeadView}
            addonBefore={formField.addonBefore}
            precision={!isSingleDigit ? 2 : 0}
            min={formField.min}
            max={formField.max}
            defaultValue={formField.defaultValue}
            step={formField.step}
            {...props}
          />
          <div className="c-form-field__error">
            {errors[formField.name] ? errors[formField.name] : undefined}
          </div>
        </div>
      </div>
    );
  };

  const SelectField = ({ formField }: { formField: FieldType }) => (
    <div className="c-form-field" key={formField.key}>
      <div className="c-form-field__label">{formField.label}:</div>
      <div className="c-form-field__wrapper">
        <Select
          // name={formField.name}
          onChange={
            formField.onChange
              ? formField.onChange
              : (v: string) => setFieldValue(formField.name, v)
          }
          value={values && values[formField.name]}
          options={formField.options ? formField.options : []}
          disabled={formField.disabled || isClientView || isAgencyLeadView}
        />
        <div className="c-form-field__error">
          {errors[formField.name] ? errors[formField.name] : undefined}
        </div>
      </div>
    </div>
  );

  const DateFieldRenderer = ({ formField }: { formField: FieldType }) => {
    return (
      <div className="c-form-field" key={formField.key}>
        <div className="c-form-field__label">{formField.label}:</div>
        <div className="c-form-field__wrapper">
          <DatePicker
            // name={formField.name}
            format={'DD-MM-YYYY'}
            disabled={formField.disabled || isClientView || isAgencyLeadView}
            onChange={(v: Dayjs | null) => {
              return setFieldValue(
                formField.name,
                v ? dayjs(v).format('DD-MM-YYYY') : null,
              );
            }}
            value={
              values[formField.name] && values[formField.name] !== 'N/A'
                ? dayjs(values[formField.name], 'DD-MM-YYYY')
                : null
            }
            className="c-date-field"
          />
          <div className="c-form-field__error">
            {errors[formField.name] ? errors[formField.name] : undefined}
          </div>
        </div>
      </div>
    );
  };

  for (const formField of formFieldsArray) {
    // number-sd = Single digit numbers.
    // number = fixed to two decimal places usually used for amount and percentages.

    if (formField.type === 'number' || formField.type === 'number-sd') {
      if (formField.hasWrapperCol) {
        fields.push(
          <Col
            sm={formField.colSpan}
            offset={isDesktopViewport ? formField.colOffset : 0}
            key={formField.name}
            xs={24}
          >
            {renderInputNumberField(formField, formField.type === 'number-sd')}
          </Col>,
        );
      } else {
        fields.push(
          renderInputNumberField(formField, formField.type === 'number-sd'),
        );
      }
    } else if (formField.type === 'text') {
      if (formField.hasWrapperCol) {
        fields.push(
          <Col
            sm={formField.colSpan}
            offset={isDesktopViewport ? formField.colOffset : 0}
            key={formField.name}
            xs={24}
          >
            {renderInputField(formField)}
          </Col>,
        );
      } else {
        fields.push(renderInputField(formField));
      }
    } else if (formField.type === 'text-area') {
      if (formField.hasWrapperCol) {
        fields.push(
          <Col
            sm={formField.colSpan}
            offset={isDesktopViewport ? formField.colOffset : 0}
            key={formField.name}
            xs={24}
          >
            {renderTextAreaField(formField)}
          </Col>,
        );
      } else {
        fields.push(renderInputField(formField));
      }
    } else if (formField.type === 'select') {
      if (formField.hasWrapperCol) {
        fields.push(
          <Col
            sm={formField.colSpan}
            offset={isDesktopViewport ? formField.colOffset : 0}
            key={formField.name}
            xs={24}
          >
            <SelectField formField={formField} key={formField.name} />
          </Col>,
        );
      } else {
        fields.push(<SelectField formField={formField} key={formField.name} />);
      }
    } else if (formField.type === 'year' || formField.type === 'month') {
      if (formField.hasWrapperCol) {
        fields.push(
          <Col
            sm={formField.colSpan}
            offset={isDesktopViewport ? formField.colOffset : 0}
            key={formField.name}
            xs={24}
          >
            {formField.type === 'year' ? (
              <YearFieldRenderer
                formField={formField}
                key={formField.name}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                isClientView={isClientView}
                isAgencyLeadView={isAgencyLeadView}
              />
            ) : (
              <MonthFieldRenderer
                formField={formField}
                key={formField.name}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                isClientView={isClientView}
                isAgencyLeadView={isAgencyLeadView}
                onChange={formField.onChange}
              />
            )}
          </Col>,
        );
      } else {
        fields.push(
          formField.type === 'year' ? (
            <YearFieldRenderer
              formField={formField}
              key={formField.name}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              isClientView={isClientView}
              isAgencyLeadView={isAgencyLeadView}
            />
          ) : (
            <MonthFieldRenderer
              formField={formField}
              key={formField.name}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              isClientView={isClientView}
              isAgencyLeadView={isAgencyLeadView}
              onChange={formField.onChange}
            />
          ),
        );
      }
    } else if (formField.type === 'date') {
      if (formField.hasWrapperCol) {
        fields.push(
          <Col
            sm={formField.colSpan}
            offset={isDesktopViewport ? formField.colOffset : 0}
            key={formField.name}
            xs={24}
          >
            <DateFieldRenderer formField={formField} key={formField.name} />
          </Col>,
        );
      } else {
        fields.push(
          <DateFieldRenderer formField={formField} key={formField.name} />,
        );
      }
    } else if (formField.type === 'placeholder') {
      if (formField.hasWrapperCol) {
        fields.push(
          <Col
            sm={formField.colSpan}
            offset={isDesktopViewport ? formField.colOffset : 0}
            key={formField.name}
            xs={24}
          >
            <div />
          </Col>,
        );
      } else {
        fields.push(<div />);
      }
      <div></div>;
    }
  }
  return fields;
};

export const formFieldsValidationSchema = (fieldsArray: FieldType[]) => {
  const fields: any = {};
  for (const field of fieldsArray) {
    if (
      field.type === 'text' ||
      field.type === 'date' ||
      field.type === 'month' ||
      field.type === 'year'
    ) {
      if (field.isRequired) {
        fields[field.name] = Yup.string()
          .trim()
          .required(field.requiredMessage)
          .nullable();
      } else {
        fields[field.name] = Yup.string().trim().nullable();
      }
    } else if (field.type === 'number') {
      if (field.isRequired) {
        if (field.min !== undefined && field.min >= 0) {
          if (field.max) {
            fields[field.name] = Yup.number()
              .required(field.requiredMessage)
              .min(
                field.min,
                `Field must be greater than or equal to ${field.min}.`,
              )
              .max(
                field.max,
                `Field must be less than or equal to ${field.max}`,
              )
              .nullable();
          } else {
            fields[field.name] = Yup.number()
              .required(field.requiredMessage)
              .min(
                field.min,
                `Field must be greater than or equal to ${field.min}.`,
              )
              .nullable();
          }
        } else {
          fields[field.name] = Yup.number()
            .required(field.requiredMessage)
            .nullable();
        }
      } else {
        fields[field.name] = Yup.number().nullable();
      }
    } else if (field.type === 'select') {
      if (field.options) {
        const values: any[] = field.options?.map((v: any) => v.value);
        if (field.isRequired) {
          fields[field.name] = Yup.mixed()
            .oneOf([...values], field.requiredMessage)
            .required(field.requiredMessage)
            .nullable();
        } else {
          fields[field.name] = Yup.mixed()
            .oneOf([...values], field.requiredMessage)
            .nullable();
        }
      }
    }
  }
  return Yup.object().shape(fields);
};

export const isError = (errors: any) =>
  Object.keys(errors).some((key) => errors[key]);

export const isTouched = (touched: any) =>
  Object.keys(touched).some((key) => touched[key]);
