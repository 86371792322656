import { Widget } from '@typeform/embed-react';
import { Progress, Result } from 'antd';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { clearInterval, setInterval } from 'worker-timers';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import useShowleadSuccessMessage from '../../../foundation/cutom_hooks/useLeadSuccessMessage';
import useRole from '../../../foundation/cutom_hooks/useRole';
import {
  addItemToStorage,
  getItemFromStorage,
} from '../../../foundation/utils/storageHandler';
import envConstants from '../../../internals/env/env_constants.json';
import { selectUser } from '../../authentication/redux/selectors';
import { selectClient } from '../../client/redux/selectors';
import { setClient } from '../../client/redux/slice';
import { Client } from '../../client/redux/types';
import { fetchDashboardData } from '../../dashboard/redux/async_thunks';
import {
  createPlan,
  fetchPlan,
  fetchPlanForStatus,
  generatePlan,
  getGenerateStatus,
} from '../../plan/redux/async_thunks';
import {
  selectLastVisitedPlan,
  selectLastVisitedPlanId,
  selectPlan,
} from '../../plan/redux/selectors';
import { setLastVisitedPlanId, setPlan } from '../../plan/redux/slice';
import { fetchPlanProperties } from '../../property/redux/async_thunks';
import {
  setPlanOffsetAccounts,
  setPlanProperties,
} from '../../property/redux/slice';
import Footer from '../footer/Footer';
import InterruptModal from '../modals/InterruptModal';

const STATUS_MAP = {
  SUCCESS: 200,
  IN_PROGRESS: 202,
  PREVIOUSLY_COMPLETED: 204,
  NOT_STARTED: 205,
  FAILED_INITIAL: 428,
};

const TIMER_DISPLAY_DURATION_SECONDS = 300;
const GENERATE_STATUS_INTERVAL_SECONDS = 30;

const ASSESSMENT_STATUS_INTERVAL_SECONDS = 2;
const MAX_ASSESSMENT_STATUS_CALL_ATTEMPTS = 7;

const PortfolioGrowthPlan = () => {
  const [, , , , , isAgencyLeadView] = useRole();

  const dispatch = useDispatch();
  const history = useHistory();
  const clientIp = getItemFromStorage('client_ip');

  const user = useSelector(selectUser);
  const client = useSelector(selectClient);
  const plan = useSelector(selectPlan);
  const lastVisitedPlan = useSelector(selectLastVisitedPlan);
  const lastVisitedPlanId = useSelector(selectLastVisitedPlanId);

  const [isLoading, setIsLoading] = useState(false);
  const [isStartScreenVisible, setIsStartScreenVisible] = useState(false);
  const [isInterruptModalVisible, setIsInterruptModalVisible] = useState(false);
  const [isTypeformVisible, setIsTypeformVisible] = useState(false);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [isPlanReadyButtonVisible, setIsPlanReadyButtonVisible] =
    useState(false);
  const [isCompletionRateVisible, setIsCompletionRateVisible] = useState(false);
  const [isWarningMessageVisible, setIsWarningMessageVisible] = useState(false);
  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
  const [isProceedGetDashboard, setIsProceedGetDashboard] = useState(false);

  const [timeLeft, setTimeLeft] = useState(TIMER_DISPLAY_DURATION_SECONDS);
  const [completionRate, setCompletionRate] = useState(0);

  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isFailedInitial, setIsFailedInitial] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);

  const [isContactMessageVisible, setIsContactMessageVisible] = useState(false);

  const planGenerationIntervalRef = useRef<any>(null);
  const assessmentIntervalRef = useRef<any>(null);
  const assessmentAttemptCountRef = useRef<any>(0);

  const resetActivity = () => {
    setIsTypeformVisible(false);
    setIsTimerVisible(false);
    setIsPlanReadyButtonVisible(false);
    setIsCompletionRateVisible(false);
    setIsWarningMessageVisible(false);
    setIsErrorMessageVisible(false);
    setIsStartScreenVisible(false);
    setIsInterruptModalVisible(false);
    setIsFailedInitial(false);
    setIsContactMessageVisible(false);

    setTimeLeft(TIMER_DISPLAY_DURATION_SECONDS);
    setCompletionRate(0);

    clearCountdownInterval();
    clearAssessmentInterval();
  };

  const errorHandler = (error: any) => {
    console.log(error);
    setIsLoading(false);
    setIsErrorMessageVisible(true);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const clearCountdownInterval = () => {
    if (planGenerationIntervalRef.current) {
      clearInterval(planGenerationIntervalRef.current);
      planGenerationIntervalRef.current = null;
    }
  };

  const clearAssessmentInterval = () => {
    if (assessmentIntervalRef.current) {
      clearInterval(assessmentIntervalRef.current);
      assessmentIntervalRef.current = null;
      assessmentAttemptCountRef.current = 0;
    }
  };

  const getPlanName = () => {
    const length = client?.planIds?.length ?? 0;
    const today = dayjs().format('DD.MM.YYYY');
    const planName = `Plan ${length + 1} - ${today}`;

    return planName;
  };

  const planInProgress = (statusResponse) => {
    setIsLoading(false);
    setIsTypeformVisible(false);
    setIsTimerVisible(true);
    setCompletionRate(statusResponse.completionRate);
  };

  /**
   * Plans created by this handler are not saved in the session storage
   */
  const getNewPlan = async () => {
    if (isLoading) {
      return;
    }

    try {
      if (user?.token) {
        setIsLoading(true);

        const data = {
          clientId: client?.clientId,
          planName: getPlanName(),
          userId: user?.user_id,
          isInternal: false,
        };

        const createdPlan: any = await dispatch(
          createPlan({ token: user.token, data: data }),
          // @ts-ignore
        ).unwrap();

        if (client) {
          const planIds: any = [...client.planIds];
          const oldClient: Client = { ...client };

          planIds.push({
            planId: createdPlan.planId,
            planName: createdPlan.planName,
          });

          oldClient.planIds = planIds;

          dispatch(setClient(oldClient));
          addItemToStorage('client', JSON.stringify(oldClient));
        }

        dispatch(setPlanProperties(undefined));
        dispatch(setPlanOffsetAccounts(undefined));
        dispatch(setLastVisitedPlanId(createdPlan?.planId));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const getStatus: any = useCallback(async () => {
    if (user && plan && !isStatusLoading) {
      try {
        setIsStatusLoading(true);

        const response = await dispatch(
          getGenerateStatus({
            token: user.token,
            planId: plan?.planId,
          }),
          // @ts-ignore
        ).unwrap();

        setIsStatusLoading(false);

        return response;
      } catch (error) {
        setIsStatusLoading(false);

        if (error?.message === 'Failed initial assessment') {
          return { status: STATUS_MAP.FAILED_INITIAL };
        } else {
          setIsInterruptModalVisible(false);
          errorHandler(error);
        }
      }
    }
  }, [user, plan, dispatch]);

  const getDashboard = async () => {
    try {
      if (user && plan) {
        setIsLoading(true);

        const reqData = {
          planId: plan?.planId,
          userId: user.user_id,
        };

        const response = await dispatch(
          fetchDashboardData({ token: user.token, values: reqData }),
          // @ts-ignore
        ).unwrap();

        const hasDashboardData = !!response;
        const statusResponse = await getStatus();

        switch (statusResponse?.status) {
          case STATUS_MAP.PREVIOUSLY_COMPLETED:
            setIsTimerVisible(false);

            if (hasDashboardData) {
              /**
               * Last /Dashboard EP call was 200
               * User will be redirected to the dashboard
               */
              getPlanProperties();
            } else {
              /**
               * Plan has errors if this line is reached:
               * - Previous /Dashboard EP call was 206
               * - /Plan/generate-status EP call was 204
               */
              setIsWarningMessageVisible(true);
              setIsLoading(false);
            }

            return;
          case STATUS_MAP.IN_PROGRESS:
            planInProgress(statusResponse);
            setIsCompletionRateVisible(true);

            return;
          case STATUS_MAP.NOT_STARTED:
          case STATUS_MAP.FAILED_INITIAL:
            setIsStartScreenVisible(true);
            setIsLoading(false);

            return;
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  /**
   * Final step after the plan is generated
   */
  const getPlanProperties = async () => {
    setIsLoading(true);

    try {
      if (user?.token && plan) {
        await Promise.all([
          dispatch(
            fetchPlan({ token: user.token, planId: plan.planId }),
            // @ts-ignore
          ).unwrap(),
          dispatch(
            fetchPlanProperties({ token: user.token, planId: plan.planId }),
            // @ts-ignore
          ).unwrap(),
        ]);
      }

      setIsLoading(false);
      history.push('/dashboard');
    } catch (error) {
      errorHandler(error);
    }
  };

  /**
   * Keep checking until response status is 202 or 428
   * Show error screen if the max attempt is reached
   */
  const checkAssessmentStatus = useCallback(async () => {
    const statusResponse = await getStatus();

    assessmentAttemptCountRef.current += 1;

    switch (statusResponse?.status) {
      case STATUS_MAP.IN_PROGRESS:
        /**
         * Proceed or resume plan generation
         */
        clearAssessmentInterval();
        setIsInterruptModalVisible(false);
        setIsTypeformVisible(false);
        setIsTimerVisible(true);

        return;
      case STATUS_MAP.FAILED_INITIAL:
        /**
         * Failed initial assessment
         * Prompt user to retry
         */
        clearAssessmentInterval();
        setIsFailedInitial(true);

        return;
      case STATUS_MAP.NOT_STARTED:
        if (
          assessmentAttemptCountRef.current >=
          MAX_ASSESSMENT_STATUS_CALL_ATTEMPTS
        ) {
          /**
           * Generate status call attempts exceeded during the assessment
           * Show error screen
           */
          clearAssessmentInterval();
          setIsTypeformVisible(false);
          setIsInterruptModalVisible(false);
          setIsErrorMessageVisible(true);
        }
        return;
    }
  }, [getStatus]);

  const checkPlanGenerationStatus = useCallback(async () => {
    const statusResponse = await getStatus();

    switch (statusResponse?.status) {
      case STATUS_MAP.IN_PROGRESS:
        planInProgress(statusResponse);

        return;
      case STATUS_MAP.SUCCESS:
        setIsCompletionRateVisible(false);
        setIsPlanReadyButtonVisible(true);
        clearCountdownInterval();

        return;
      /**
       * Should never reach this case
       * 428 is unexpected
       * 204 indicates plan was completed
       */
      case STATUS_MAP.PREVIOUSLY_COMPLETED:
      case STATUS_MAP.FAILED_INITIAL:
        setIsTimerVisible(false);
        setIsWarningMessageVisible(true);
        setIsLoading(false);

        return;
    }
  }, [getStatus]);

  const retryPlanGeneration = async () => {
    setIsLoading(true);

    try {
      if (user?.token && plan) {
        const planInputsResponse = await dispatch(
          fetchPlanForStatus({ token: user.token, planId: plan.planId }),
          // @ts-ignore
        ).unwrap();

        if (
          planInputsResponse?.responseCode === 200 &&
          planInputsResponse?.planInputs
        ) {
          const generateResponse = await dispatch(
            generatePlan({
              data: {
                userId: user.user_id,
                planId: plan?.planId,
                ...omit(
                  planInputsResponse?.planInputs,
                  'loanPiTerm',
                  'targetYear',
                ),
              },
              token: user?.token,
            }), // @ts-ignore
          ).unwrap();

          if (generateResponse.responseCode === 202) {
            setIsWarningMessageVisible(false);
            planInProgress(0);
          } else {
            setIsContactMessageVisible(true);
          }
        } else {
          setIsContactMessageVisible(true);
        }
      }

      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleIntteruptModalClose = () => {
    setIsInterruptModalVisible(false);
  };

  const handleReloadTypeform = () => {
    setIsInterruptModalVisible(false);
    setIsTypeformVisible(true);
    setIsFailedInitial(false);
    setReloadKey((prevKey) => prevKey + 1);
  };

  const typeFormWidget = useMemo(() => {
    if (!plan) {
      return <></>;
    }

    const hiddenIds = {
      userid: user?.user_id ?? '',
      planid: plan?.planId ?? '',
      ipaddress: clientIp ?? '',
    };

    return (
      <Widget
        key={reloadKey}
        id={envConstants.TYPEFORM_ID}
        className="l-portfolio-growth-plan__typeform"
        hidden={hiddenIds}
        onReady={() => {
          setIsLoading(false);
        }}
        onSubmit={() => {
          /**
           * Display the interrupt modal while checking the financial viability
           */
          let isFirstIteration = true;

          setIsInterruptModalVisible(true);

          assessmentAttemptCountRef.current = 0;

          assessmentIntervalRef.current = setInterval(() => {
            if (isFirstIteration) {
              isFirstIteration = false;
            } else {
              checkAssessmentStatus();
            }
          }, ASSESSMENT_STATUS_INTERVAL_SECONDS * 1000);
        }}
        inlineOnMobile
      />
    );
  }, [plan, reloadKey]);

  const timerComponent = useMemo(() => {
    if (!plan) {
      return <></>;
    }

    return (
      <div className="l-portfolio-growth-plan__timer">
        {!isPlanReadyButtonVisible && (
          <>
            <h1 className="l-portfolio-growth-plan__heading">
              Your plan is being built
            </h1>
            {!isPlanReadyButtonVisible && !isCompletionRateVisible && (
              <h1 className="l-portfolio-growth-plan__timer-display">
                {formatTime(timeLeft)}
              </h1>
            )}
            {isCompletionRateVisible && (
              <>
                <h1
                  style={{ textAlign: 'center' }}
                  className="l-portfolio-growth-plan__timer-display"
                >
                  {completionRate}%
                  <small
                    style={{
                      marginTop: '-15px',
                      fontSize: '14px',
                      display: 'block',
                    }}
                  >
                    Complete
                  </small>
                </h1>

                <Progress
                  showInfo={false}
                  percent={completionRate}
                  status="active"
                  strokeColor={{ from: '#00b2a3', to: '#55bd7e' }}
                  style={{ maxWidth: '600px', margin: '0 auto 30px' }}
                />
              </>
            )}
            <div style={{ maxWidth: '420px' }}>
              <p className="l-portfolio-growth-plan__start-text">
                As a first of its kind,{' '}
                <span className="l-portfolio-growth-plan__brand">
                  Property Pathfinder
                </span>{' '}
                is a powerful platform, and it takes a few minutes to generate
                your personalised plan.
              </p>
              <p className="l-portfolio-growth-plan__start-text">
                A button will appear above when your plan is ready.
              </p>
            </div>
          </>
        )}
        {isPlanReadyButtonVisible && (
          <>
            <h1
              className="l-portfolio-growth-plan__heading"
              style={{ marginBottom: '25px' }}
            >
              Your plan is ready
            </h1>
            <div className="l-portfolio-growth-plan__enter-button">
              <button
                type="button"
                onClick={() => {
                  setIsTimerVisible(false);
                  getPlanProperties();
                }}
              >
                Access Your Plan
              </button>
            </div>
          </>
        )}
      </div>
    );
  }, [
    plan,
    timeLeft,
    completionRate,
    isCompletionRateVisible,
    isPlanReadyButtonVisible,
  ]);

  const renderStartScreen = () => {
    return (
      <div className="l-portfolio-growth-plan__timer">
        <h1 className="l-portfolio-growth-plan__heading">
          LET&apos;S CREATE YOUR
          <br />
          PORTFOLIO GROWTH PLAN
        </h1>
        <p className="l-portfolio-growth-plan__start-text">
          It takes approximately 5 minutes to complete
        </p>
        <p
          className="l-portfolio-growth-plan__start-text"
          style={{ marginTop: '10px' }}
        >
          <strong style={{ fontSize: '20px', fontWeight: '500' }}>
            How it works:
          </strong>
        </p>
        <p className="l-portfolio-growth-plan__start-text">
          1. Complete this quick Profile Builder
        </p>
        <p className="l-portfolio-growth-plan__start-text">
          2. Once you submit,{' '}
          <span className="l-portfolio-growth-plan__brand">
            Property Pathfinder
          </span>{' '}
          will build a personalised Portfolio Growth Plan
        </p>
        <p className="l-portfolio-growth-plan__start-text">
          3. Access your Portfolio Growth Plan
        </p>

        <p
          className="l-portfolio-growth-plan__start-text"
          style={{ marginTop: '10px', marginBottom: '25px' }}
        >
          <strong style={{ fontSize: '20px', fontWeight: '500' }}>
            Ready to begin?
          </strong>
        </p>
        <div
          className="l-portfolio-growth-plan__enter-button"
          style={{ padding: 0 }}
        >
          <button
            type="button"
            onClick={() => {
              setIsStartScreenVisible(false);
              setIsTypeformVisible(true);
            }}
            className="l-portfolio-growth-plan__enter-button"
          >
            Start Building
          </button>
        </div>
      </div>
    );
  };

  useShowleadSuccessMessage(user);

  useEffect(() => {
    if (!isTimerVisible) {
      setTimeLeft(TIMER_DISPLAY_DURATION_SECONDS);
      setIsPlanReadyButtonVisible(false);

      if (planGenerationIntervalRef.current) {
        clearInterval(planGenerationIntervalRef.current);
        planGenerationIntervalRef.current = null;
      }

      return;
    }

    planGenerationIntervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      if (planGenerationIntervalRef.current) {
        clearInterval(planGenerationIntervalRef.current);
        planGenerationIntervalRef.current = null;
      }
    };
  }, [isTimerVisible]);

  useEffect(() => {
    if (isTimerVisible) {
      if (timeLeft === 0) {
        setIsCompletionRateVisible(true);
      }

      if (
        isTimerVisible &&
        timeLeft % GENERATE_STATUS_INTERVAL_SECONDS === 0 &&
        timeLeft !== TIMER_DISPLAY_DURATION_SECONDS
      ) {
        checkPlanGenerationStatus();
      }
    }
  }, [timeLeft, checkPlanGenerationStatus, isTimerVisible]);

  useEffect(() => {
    if (user) {
      resetActivity();
      clearCountdownInterval();

      if (lastVisitedPlan) {
        // @ts-ignore
        dispatch(setPlan(lastVisitedPlan));
        setIsProceedGetDashboard(true);
      } else if (!lastVisitedPlanId) {
        getNewPlan();
      }
    }
  }, [lastVisitedPlanId]);

  useEffect(() => {
    /**
     * Available planInputs indicates that getPlanProperties has been invoked
     */
    if (plan && !plan.planInputs && isProceedGetDashboard) {
      getDashboard();
      setIsProceedGetDashboard(false);
    }
  }, [plan, isProceedGetDashboard]);

  return (
    <>
      {isInterruptModalVisible && (
        <InterruptModal
          closeModal={handleIntteruptModalClose}
          reloadTypeform={handleReloadTypeform}
          isFailedInitial={isFailedInitial}
        />
      )}
      <div className="l-portfolio-growth-plan">
        {isLoading && <FullPageLoader />}
        {isTypeformVisible && typeFormWidget}
        {isTimerVisible && timerComponent}
        {isStartScreenVisible && <>{renderStartScreen()}</>}
        {isWarningMessageVisible && (
          <div className="l-portfolio-growth-plan__message">
            <Result
              status="warning"
              title={
                <>
                  There are some problems with this plan.
                  <br />
                  You may restart the build process.
                  <div
                    className="l-portfolio-growth-plan__enter-button"
                    style={{
                      padding: 0,
                      minHeight: '50px',
                      marginBottom: '20px',
                      marginTop: '10px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: '#00b2a3',
                        textTransform: 'none',
                        width: '300px',
                        margin: '20px auto',
                      }}
                      onClick={() => {
                        retryPlanGeneration();
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                  {isContactMessageVisible && (
                    <>
                      Error occured
                      <br /> Please contact support or check back later.
                    </>
                  )}
                </>
              }
            />
          </div>
        )}
        {isErrorMessageVisible && (
          <div className="l-portfolio-growth-plan__message">
            <Result
              status="error"
              title={
                <>
                  An error occurred during the process.
                  <br />
                  Please contact support.
                </>
              }
            />
          </div>
        )}
      </div>
      {isAgencyLeadView && <Footer />}
    </>
  );
};

export default PortfolioGrowthPlan;
