import { HomeOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import { defaults } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import { merge } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import BarChart from '../../foundation/assets/svgs/BarChart';
import Dollar from '../../foundation/assets/svgs/Dollar';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import CustomSelect from '../../foundation/components/select/Select';
import YearField from '../../foundation/components/year_field/YearField';
import useRole from '../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import { fetchClientEmails } from '../client/redux/async_thunks';
import { selectClient } from '../client/redux/selectors';
import VideoExplainer from '../lead/video_explainer/VideoExplainer';
import withPointer from '../lead/video_explainer/withPointer';
import { selectPlan } from '../plan/redux/selectors';
import { selectPerformanceTimelineTypes } from '../property/redux/selectors';
import AnnualCashflowSection from './elements/AnnualCashflowSection';
import EquityGrowthSection from './elements/EquityGrowthSection';
import MonthlyCashflowSection from './elements/MonthlyCashflowSection';
import NextPurchaseCard from './elements/NextPurchaseCard';
import PortfolioPerformanceSection from './elements/PortfolioPerformanceSection';
import ProgressCard from './elements/ProgressCard';
import PurchaseRoadmapSection from './elements/PurchaseRoadmapSection';
import ShareReportModal from './elements/ShareReportModal';
import StatsCard from './elements/StatsCard';
import { fetchDashboardData } from './redux/async_thunks';
import {
  selectDashboardAchievedYear,
  selectDashboardAssetResult,
  selectDashboardCashflowResult,
  selectDashboardNumberOfProperties,
  selectDashboardPlanTargetCalendarYear,
  selectDashboardPortfolioValue,
  selectDashboardTimelineYears,
  selectDashboardTotalDebt,
  selectDashboardTotalEquity,
} from './redux/selectors';
import { resetDashboard } from './redux/slice';

const DraggablePointer = withPointer();

merge(defaults.font, {
  size: 13,
  family: 'Inter',
});

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const { isDesktopViewport, isLargeTabletViewport } = useViewport();

  const [
    isClientView,
    ,
    ,
    isAgencyAdminView,
    isAgencyUserView,
    isAgencyLeadView,
  ] = useRole();

  const [isLoading, setIsLoading] = useState(true);

  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const [timeline, setTimeline] = useState<string | undefined>(undefined);

  const user = useSelector(selectUser);

  const plan = useSelector(selectPlan);

  const client = useSelector(selectClient);

  const cashFlowResult = useSelector(selectDashboardCashflowResult);

  const targetCalendarYear = useSelector(selectDashboardPlanTargetCalendarYear);

  const selectedAchievedYear = useSelector(selectDashboardAchievedYear);

  const performanceTimelineYears = useSelector(selectPerformanceTimelineTypes);

  const timelineYears = useSelector(selectDashboardTimelineYears);

  const assetResult = useSelector(selectDashboardAssetResult);
  const numOfProperties = useSelector(selectDashboardNumberOfProperties);
  const portfolioValue = useSelector(selectDashboardPortfolioValue);
  const totalDebt = useSelector(selectDashboardTotalDebt);
  const totalEquity = useSelector(selectDashboardTotalEquity);

  const [achievedYear, setAchievedYear] = useState<string | null>(null);

  const [explainerTargetRef, setExplainerTargetRef] = useState<any>(null);
  const [explainerVideoMediaId, setExplainerVideoMediaId] = useState<any>(null);

  /**
   * Fetches the dashboard data
   */
  const fetchData = async (
    passedTimeline?: number,
    passedAchievedYear?: string,
  ) => {
    try {
      if (user?.token && plan && client) {
        setIsLoading(true);

        let years: any = null;

        /**
         * If we have a passedTimeline value then we will use that
         * otherwise we will use the timeline state variable. We have to do it
         * because we are using this function on change of timeline dropdown.
         */
        if (passedTimeline) {
          years = passedTimeline;
        } else if (timeline) {
          years = parseInt(timeline);
        }

        const reqData = {
          planId: plan?.planId,
          userId: user.user_id,
          timelineYears: years,
          achievedYear: passedAchievedYear || achievedYear,
        };

        const promiseArray = [
          dispatch(
            fetchDashboardData({ token: user.token, values: reqData }),
            // @ts-ignore
          ).unwrap(),
        ];

        if (isAgencyAdminView || isAgencyUserView) {
          promiseArray.push(
            dispatch(
              fetchClientEmails({
                token: user.token,
                clientId: client.clientId,
              }),
            ).unwrap(),
          );
        }

        await Promise.all(promiseArray);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleShareClick = async () => {
    setIsShareModalVisible(true);
  };

  const handleShareModalClose = () => {
    setIsShareModalVisible(false);
  };

  const handleTimelineChange = (v: string) => {
    setTimeline(v);
    fetchData(parseInt(v));
  };

  const handleAchievedYearChange = (date: Dayjs) => {
    setAchievedYear(date.format('YYYY'));
    fetchData(undefined, date.format('YYYY'));
  };

  const handleCloseExplainerVideo = () => {
    setExplainerTargetRef(null);
    setExplainerVideoMediaId(null);
  };

  const explainerStates = {
    setExplainerTargetRef: setExplainerTargetRef,
    setExplainerVideoMediaId: setExplainerVideoMediaId,
    explainerVideoMediaId: explainerVideoMediaId,
    isLoading: isLoading,
  };

  const draggablePointer = useMemo(() => {
    if (!explainerTargetRef) {
      return null;
    }

    return (
      <DraggablePointer
        targetRef={explainerTargetRef}
        videoMediaId={explainerVideoMediaId}
        closeHandler={handleCloseExplainerVideo}
      />
    );
  }, [explainerTargetRef, explainerVideoMediaId]);

  useEffect(() => {
    if (selectedAchievedYear) {
      setAchievedYear(`${selectedAchievedYear}`);
    }
  }, [selectedAchievedYear]);

  useEffect(() => {
    if (timelineYears) {
      setTimeline(`${timelineYears}`);
    }
  }, [timelineYears]);

  useEffect(() => {
    const typeCheck = 'boolean';

    if (
      typeof isAgencyAdminView === typeCheck ||
      typeof isAgencyUserView === typeCheck
    ) {
      fetchData();
    }
  }, [isAgencyAdminView, isAgencyUserView]);

  useEffect(
    () => () => {
      dispatch(resetDashboard());
    },
    [],
  );

  return (
    <div className="dashboard">
      {isLoading && <FullPageLoader classNames="dashboard__loader" />}
      {isShareModalVisible && (
        <ShareReportModal closeModal={handleShareModalClose} />
      )}
      <div className="dashboard__header">
        {isDesktopViewport && (
          <div className="dashboard__header-title">
            Property Portfolio Dashboard
          </div>
        )}
        <div className="dashboard__header-fields">
          <div className="dashboard__header-field">
            <div className="dashboard__header-field-label">Target Year:</div>
            <Input
              value={targetCalendarYear}
              disabled
              className="dashboard__header-field-input"
            />
          </div>
          <div className="dashboard__header-field">
            <div className="dashboard__header-field-label">Achieved Year:</div>
            <VideoExplainer {...explainerStates} videoMediaIdKey="achievedYear">
              <YearField
                onChange={handleAchievedYearChange}
                picker="year"
                value={dayjs(achievedYear, 'YYYY')}
                className="c-date-field dashboard__header-field-input"
                disabled={isClientView || isAgencyLeadView}
                style={{ width: '100%' }}
              />
            </VideoExplainer>
          </div>
          <div className="dashboard__header-field">
            <div className="dashboard__header-field-label">
              Dashboard Timeline:
            </div>

            <VideoExplainer
              {...explainerStates}
              videoMediaIdKey="dashboardTimeline"
            >
              <CustomSelect
                placeholder="Years"
                options={performanceTimelineYears || []}
                className="cashflow-projection__performace-timeline-select dashboard__header-field-input"
                onChange={handleTimelineChange}
                value={timeline}
              />
            </VideoExplainer>
          </div>
          {!isClientView && !isAgencyLeadView && (
            <div className="dashboard__header-field">
              <Button
                className="dashboard__header-share"
                type="primary"
                onClick={handleShareClick}
              >
                Share
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="dashboard__content-wrapper">
        <Row
          gutter={[20, 16]}
          style={isDesktopViewport ? { marginBottom: 20 } : {}}
        >
          <Col xs={24} lg={17}>
            <Row gutter={[20, 16]} style={{ marginBottom: 20 }}>
              <Col xs={24} sm={12}>
                <VideoExplainer
                  {...explainerStates}
                  videoMediaIdKey="portfolioCashFlow"
                >
                  <ProgressCard
                    percentage={cashFlowResult?.percentage}
                    actual={cashFlowResult?.actual}
                    goal={cashFlowResult?.goal}
                    percentageTitle="Portfolio Cash Flow"
                    actualValueLabel="Annual Cashflow at Achieved Year"
                    strokeColor="#00B2A3"
                  />
                </VideoExplainer>
              </Col>
              <Col xs={24} sm={12}>
                <ProgressCard
                  percentage={assetResult?.percentage}
                  actual={assetResult?.actual}
                  goal={assetResult?.goal}
                  percentageTitle="Portfolio Equity"
                  strokeColor="#0BA5EC"
                  actualValueLabel="Portfolio Equity at Achieved Year"
                />
              </Col>
            </Row>
            <Row
              gutter={[20, 16]}
              style={
                isDesktopViewport || isLargeTabletViewport
                  ? { marginBottom: 20 }
                  : {}
              }
              className="dashbboard__stats-row"
            >
              <Col xs={12} md={6} xl={6}>
                <VideoExplainer
                  {...explainerStates}
                  videoMediaIdKey="numberOfProperties"
                >
                  <StatsCard
                    icon={<HomeOutlined />}
                    label="Properties Owned"
                    stats={numOfProperties ? `${numOfProperties}` : ''}
                    formatNumber={false}
                    background={`#D6F6F4`}
                    color={`#019386`}
                  />
                </VideoExplainer>
              </Col>
              <Col xs={12} md={6} xl={6}>
                <VideoExplainer
                  {...explainerStates}
                  videoMediaIdKey="portfolioValue"
                >
                  <StatsCard
                    icon={<Dollar />}
                    label="Portfolio Value"
                    stats={portfolioValue ? `${portfolioValue}` : ''}
                    formatNumber={true}
                    background={`#E0F2FE`}
                    color={`#0086C9`}
                  />
                </VideoExplainer>
              </Col>
              <Col xs={12} md={6} xl={6}>
                <VideoExplainer
                  {...explainerStates}
                  videoMediaIdKey="totalDebt"
                >
                  <StatsCard
                    icon={<Dollar />}
                    label="Total Debt"
                    stats={totalDebt ? `${totalDebt}` : ''}
                    formatNumber={true}
                    background={`#D1FADF`}
                    color={`#039855`}
                  />
                </VideoExplainer>
              </Col>
              <Col xs={12} md={6} xl={6}>
                <VideoExplainer
                  {...explainerStates}
                  videoMediaIdKey="totalEquity"
                >
                  <StatsCard
                    icon={<BarChart />}
                    label="Total Equity"
                    stats={totalEquity ? `${totalEquity}` : ''}
                    formatNumber={true}
                    background={`#FEF0C7`}
                    color={`#DC6803`}
                  />
                </VideoExplainer>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={7} className="dashboard__np">
            <VideoExplainer {...explainerStates} videoMediaIdKey="nextPurchase">
              <NextPurchaseCard />
            </VideoExplainer>
          </Col>
        </Row>
        <VideoExplainer
          {...explainerStates}
          videoMediaIdKey="propertyPurchaseRoadmap"
        >
          <PurchaseRoadmapSection />
        </VideoExplainer>
        <VideoExplainer
          {...explainerStates}
          videoMediaIdKey="howDoesThePortfolioPerform"
        >
          <PortfolioPerformanceSection
            // @ts-ignore
            timeline={timeline ? parseInt(timeline) : undefined}
          />
        </VideoExplainer>
        <VideoExplainer
          {...explainerStates}
          videoMediaIdKey="annualCashFlowGraph"
        >
          <AnnualCashflowSection />
        </VideoExplainer>
        <MonthlyCashflowSection />
        <VideoExplainer
          {...explainerStates}
          videoMediaIdKey="equityGrowthGraph"
        >
          <EquityGrowthSection />
        </VideoExplainer>
        {/* <AboutPropertiesDetails /> */}
      </div>
      {draggablePointer}
    </div>
  );
};

export default Dashboard;
