import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import useRole from '../../../foundation/cutom_hooks/useRole';
import { selectUser } from '../../authentication/redux/selectors';
import { setNavIndex } from '../../profile/redux/slice';

const RemiCtaButton = ({
  isHeader,
  style = {},
  inset,
}: {
  isHeader?: boolean;
  style?: any;
  inset?: boolean;
}) => {
  const REMI_ROUTE = '/remi';

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [, , , , , isAgencyLeadView] = useRole();

  const user = useSelector(selectUser);

  const isBuildStep =
    user?.ctaPlanDetails?.goodFit === null || user?.ctaPlanDetails === null;

  const handleCta = () => {
    dispatch(setNavIndex(2));
    if (user?.ctaPlanDetails?.goodFit === true) {
      history.push('/book-a-call');
    } else if (
      user?.ctaPlanDetails === null ||
      user?.ctaPlanDetails?.goodFit === null
    ) {
      history.push('/portfolio-growth-plan');
    }
  };

  const isVisible =
    isAgencyLeadView &&
    ((isHeader && location?.pathname?.includes(REMI_ROUTE)) || !isHeader);

  if (!isVisible) {
    return null;
  }

  const insetColor = '#01938644';

  return (
    <div
      className="l-remi-cta"
      style={isHeader ? { marginRight: '30px' } : style}
    >
      <div
        className="l-remi-cta__actions"
        style={isHeader ? { marginBottom: 0 } : {}}
      >
        <div
          className="l-remi-cta__cta"
          style={
            isHeader
              ? {
                  padding: '0',
                  backgroundColor: '#effffe',
                  boxShadow: `inset 0 0 0 1px ${insetColor}`,
                }
              : { border: inset ? `1px solid ${insetColor}` : 'none' }
          }
        >
          {isBuildStep && (
            <span
              style={
                isHeader
                  ? {
                      margin: '0 15px 0 20px',
                    }
                  : {}
              }
            >
              What&apos;s next?
            </span>
          )}
          <Button
            className={`l-remi-cta__cta-button${isHeader ? ' fancy' : ''}`}
            type="primary"
            shape="round"
            onClick={handleCta}
          >
            {isBuildStep ? (
              <>Build my Portfolio Plan</>
            ) : (
              <>Take the Next Step</>
            )}
            {!isHeader && <ArrowRightOutlined />}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RemiCtaButton;
