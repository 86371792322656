import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import useRole from '../../../foundation/cutom_hooks/useRole';
import { selectUser } from '../../authentication/redux/selectors';
import { selectPlan } from '../../plan/redux/selectors';

const CtaButton = ({ isHeader }: { isHeader?: boolean }) => {
  const BOOKING_ROUTE = '/book-a-call';

  const history = useHistory();
  const location = useLocation();
  const [, , , , , isAgencyLeadView] = useRole();

  const user = useSelector(selectUser);
  const plan = useSelector(selectPlan);

  const isVisible =
    isAgencyLeadView &&
    ((isHeader &&
      plan &&
      plan.planName &&
      location?.pathname !== BOOKING_ROUTE) ||
      !isHeader);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {user?.ctaPlanDetails?.goodFit && (
        <div
          className="l-lead-cta"
          style={isHeader ? { position: 'unset', marginRight: '30px' } : {}}
        >
          <Button
            style={
              isHeader
                ? {
                    height: '44px',
                    border: 'none',
                    width: '200px',
                  }
                : {}
            }
            className="l-lead-cta__button"
            type="primary"
            shape="round"
            onClick={() => {
              history.push('/book-a-call');
            }}
          >
            Take the Next Step
            {!isHeader && <ArrowRightOutlined />}
          </Button>
        </div>
      )}
    </>
  );
};

export default CtaButton;
