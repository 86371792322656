import React, {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useRef,
} from 'react';

import useRole from '../../../foundation/cutom_hooks/useRole';

const EXPLAINER_VIDEOS_MEDIA_ID = {
  portfolioCashFlow: 'ca71g646nh',
  numberOfProperties: 'dr6161jvxl',
  portfolioValue: '0k8ltc2tpc',
  totalDebt: 'p6otnhjkcn',
  totalEquity: '90ujow3eg5',
  nextPurchase: 'r9ie92r4gs',
  achievedYear: 'wsyewf2tns',
  dashboardTimeline: '5onney9phf',
  propertyPurchaseRoadmap: '1vlg9fwnco',
  howDoesThePortfolioPerform: '629oyt399m',
  annualCashFlowGraph: 'h9ee9uwbah',
  equityGrowthGraph: '38k9j4mqqb',
};

type VideoExplainerProps = {
  children: ReactNode;
  setExplainerTargetRef: Dispatch<
    SetStateAction<RefObject<HTMLElement> | null>
  >;
  setExplainerVideoMediaId: Dispatch<SetStateAction<string | null>>;
  videoMediaIdKey: string;
  isLoading: boolean;
  explainerVideoMediaId?: string;
};

const VideoExplainer = ({
  children,
  setExplainerTargetRef,
  setExplainerVideoMediaId,
  videoMediaIdKey,
  isLoading,
  explainerVideoMediaId,
}: VideoExplainerProps) => {
  const targetRef: any = useRef(null);
  const [, , , , , isAgencyLeadView] = useRole();

  if (!isAgencyLeadView) {
    return <>{children}</>;
  }
  const videoMediaIdValue = EXPLAINER_VIDEOS_MEDIA_ID[videoMediaIdKey];
  const isActive = explainerVideoMediaId?.includes(videoMediaIdValue);

  const handleClick = () => {
    if (isActive) {
      setExplainerTargetRef(null);
      setExplainerVideoMediaId(null);
    } else {
      setExplainerTargetRef(targetRef);
      setExplainerVideoMediaId(videoMediaIdValue);
    }
  };

  return (
    <div className="l-video-explainer-trigger">
      {children}
      <button
        className={`l-video-explainer-trigger__button${isActive ? ' l-video-explainer-trigger__button--active' : ''}`}
        ref={targetRef}
        onClick={handleClick}
        style={isLoading ? { borderColor: '#fff' } : {}}
      >
        {isLoading && <div className="l-video-explainer-trigger__overlay" />}?
      </button>
    </div>
  );
};

export default VideoExplainer;
