import { Layout } from 'antd';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useRouteConfig from '../../foundation/cutom_hooks/useRouteConfig';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import CtaButton from '../lead/cta_button/CtaButton';
import { selectNavIndex } from '../profile/redux/selectors';
import ContentWrapper from './content_wrapper/ContentWrapper';
import Header from './header/Header';
import PGPMenu from './pgp_menu/PGPMenu';
import Sider from './sider/Sider';

type Props = {
  children: ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const history = useHistory();
  const {
    isDesktopViewport,
    isMobileViewport,
    isLargeOrVeryLargeDesktopViewport,
  } = useViewport();

  const [isPGPMobileMenuVisible, setIsPGPMobileMenuVisible] = useState(false);

  const navIndex = useSelector(selectNavIndex);

  const routeConfig = useRouteConfig(history.location.pathname);

  const isPGPScreen = navIndex === 2;

  return (
    <Layout
      className="c-app-layout"
      style={isPGPScreen && isMobileViewport ? { paddingBottom: '65px' } : {}}
    >
      <Header routeConfig={routeConfig}>Header</Header>
      {!isDesktopViewport && (
        <PGPMenu
          onClick={() => setIsPGPMobileMenuVisible(false)}
          routeConfig={routeConfig}
          isVisible={isPGPMobileMenuVisible}
        />
      )}
      {routeConfig?.displaySidebar && (
        <Layout
          style={{
            padding: isDesktopViewport ? '25px' : '15px',
            position: 'relative',
          }}
        >
          {/*
            Only display side bar on desktop and large tablet views, right now we are using this only for PGP
            so this logic works for now. In the future when we will add more items to sidebar
            then we will have to change this
           */}
          {isDesktopViewport && <Sider routeConfig={routeConfig} />}
          <ContentWrapper
            routeConfig={routeConfig}
            setPGPMobileMenuVisibility={setIsPGPMobileMenuVisible}
          >
            {children}
          </ContentWrapper>
          {!isLargeOrVeryLargeDesktopViewport && <CtaButton />}
        </Layout>
      )}
      {!routeConfig?.displaySidebar && <>{children}</>}
    </Layout>
  );
};

export default AppLayout;
